import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../../components/Layout'
import ServiceFooter from '../../components/ServiceFooter'

const Gate = () => {
  const {
    site: {
      siteMetadata: { title },
    },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Layout>
      <Helmet>
        <html className="temp" lang="ja" />
        <title>{`ゲート式駐車場管理システム | ${title}`}</title>
        <meta
          name="description"
          content="水濡れ・汚れ・折れ曲がりにつよいバーコード駐車券の採用。インターネット接続による遠隔操作で、大規模ゲート式駐車場の運営効率を大きく向上しました。"
        />
      </Helmet>

      <div className="common-header clearfix">
        <p className="category pc-only">製品・サービス</p>
        <h1>ゲート式駐車場管理システム</h1>
        <p className="category sp-only">製品・サービス</p>

        <div className="breadcrumb">
          <li>
            <Link to={`/`}>トップページ</Link>
          </li>
          /
          <li>
            <Link to={`/service`}>製品・サービス</Link>
          </li>
          /<li>ゲート式駐車場管理システム</li>
        </div>
      </div>

      <div className="main-wrapper gate">
        <div className="row">
          <div className="container empty"></div>
        </div>

        <div className="row">
          <div className="h-wrapper">
            <h2>ゲート式駐車場管理システム</h2>
            <div className="common-btn pc-only">
              <a
                href="/assets/files/whitepaper/itc-gateparking.pdf"
                target="_blank"
              >
                カタログダウンロード
              </a>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="container top">
            <h4>
              水濡れ・汚れ・折れ曲がりにつよいバーコード駐車券の採用。インターネット接続による遠隔操作で、大規模ゲート式駐車場の運営効率を大きく向上しました。
            </h4>

            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/common/service-gate-01.webp"
              />
              <img
                className="gate-01"
                src="/assets/images/common/service-gate-01.png"
                height="710"
                alt=""
              />
            </picture>

            <h5>ここがアイテック！</h5>

            <div className="summary full">
              <ul>
                <li>
                  インターネットを活用し、遠隔操作による管理を可能に。設置したモニターカメラのリアルタイム映像も見られます。
                </li>
                <li>
                  入出庫状況や売上・集金データ、さまざまなサービス情報などを収集し表示。
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="h-wrapper">
            <h3>ゲート式駐車場管理システムITC-GT</h3>
          </div>
        </div>

        <div className="row">
          <div className="container cont-01">
            <p>
              ITC-GTは、インターネット接続により多彩な機能・操作を可能に。
              <br />
              バーコード方式の駐車券で、消耗品のランニングコストも抑えたECOシステムです。
            </p>

            <h5>特徴</h5>

            <ul className="small-points">
              <li>遠隔操作できめ細かな対処</li>
              <li>駐車券紛失時も正規料金で精算</li>
              <li>発券機からもできる領収書発行</li>
              <li>モニターカメラとの併用でさらに多くのデータを収集</li>
              <li>紙切れを未然に防ぐ、駐車券発行機能をW装備</li>
            </ul>

            <div className="separate-area">
              <div className="box wide">
                <picture>
                  <source
                    type="image/webp"
                    srcset="/assets/images/common/service-gate-02.webp"
                  />
                  <img
                    className="gate-02"
                    src="/assets/images/common/service-gate-02.png"
                    height="900"
                    alt=""
                  />
                </picture>
                <p>精算機</p>
              </div>
              <div className="box wide">
                <picture>
                  <source
                    type="image/webp"
                    srcset="/assets/images/common/service-gate-03.webp"
                  />
                  <img
                    className="gate-03"
                    src="/assets/images/common/service-gate-03.png"
                    height="900"
                    alt=""
                  />
                </picture>
                <p>発券機</p>
              </div>
            </div>

            <h5>精算機 標準仕様</h5>

            <table>
              <tbody>
                <tr>
                  <th>外形寸法</th>
                  <td>H1372mm×W567mm×D380mm（突起部含む）</td>
                </tr>
                <tr>
                  <th>重量</th>
                  <td>約120kg</td>
                </tr>
                <tr>
                  <th>電源電圧</th>
                  <td>AC100V 50／60Hz</td>
                </tr>
                <tr>
                  <th>消費電力</th>
                  <td>待機時50W 稼働時80W(AC100V)</td>
                </tr>
                <tr>
                  <th>使用環境</th>
                  <td>温度 0℃〜45℃　湿度 結露しないこと</td>
                </tr>
                <tr>
                  <th>対応貨幣</th>
                  <td>10円・50円・100円・500円硬貨　1000円紙幣</td>
                </tr>
                <tr>
                  <th>釣銭払出</th>
                  <td>循環式</td>
                </tr>
                <tr>
                  <th>領収書発行</th>
                  <td className="multi">
                    約600枚印字可能
                    <br />
                    ※領収書の駐車場名及び会社名は出荷時に設定
                  </td>
                </tr>
                <tr>
                  <th>料金設定</th>
                  <td>
                    平日・土・日・祝日　それぞれの曜日に対し各3段階設定可能
                  </td>
                </tr>
                <tr>
                  <th>表示部</th>
                  <td>メッセージ 全角16文字　料金 6桁 0〜999,999</td>
                </tr>
                <tr>
                  <th>その他(オプション)</th>
                  <td>交通系電子マネー ／ クレジットカード</td>
                </tr>
              </tbody>
            </table>

            <h5>発券機 標準仕様</h5>

            <table>
              <tbody>
                <tr>
                  <th>外形寸法</th>
                  <td>H1300mm×W434mm×D505mm（突起部含む）</td>
                </tr>
                <tr>
                  <th>重量</th>
                  <td>約60kg</td>
                </tr>
                <tr>
                  <th>電源電圧</th>
                  <td>AC100V 50／60Hz</td>
                </tr>
                <tr>
                  <th>消費電力</th>
                  <td>待機時30W 稼働時80W(AC100V)</td>
                </tr>
                <tr>
                  <th>使用環境</th>
                  <td>温度 0℃〜45℃　湿度 結露しないこと</td>
                </tr>
                <tr>
                  <th>発券枚数</th>
                  <td>約3600枚（ロール紙1本　約1800枚）</td>
                </tr>
                <tr>
                  <th>ロール紙</th>
                  <td>保有枚数 2本</td>
                </tr>
                <tr>
                  <th>その他</th>
                  <td className="multi">
                    遠隔操作で領収書発行　定期券パス券読取機構
                    <br />
                    音声ガイダンス
                  </td>
                </tr>
              </tbody>
            </table>

            <h5>ゲート機 標準仕様</h5>

            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/common/service-gate-04.webp"
              />
              <img
                className="gate-04"
                src="/assets/images/common/service-gate-04.png"
                height="890"
                alt=""
              />
            </picture>

            <table>
              <tbody>
                <tr>
                  <th>外形寸法</th>
                  <td>H1035mm×W325mm×D458mm（突起部含む/バーは含まず）</td>
                </tr>
                <tr>
                  <th>重量</th>
                  <td>約30kg</td>
                </tr>
                <tr>
                  <th>電源電圧</th>
                  <td>AC100V 50／60Hz</td>
                </tr>
                <tr>
                  <th>消費電力</th>
                  <td>待機時10W 稼働時100W(AC100V)</td>
                </tr>
                <tr>
                  <th>使用環境</th>
                  <td>温度 0℃〜45℃　湿度 結露しないこと</td>
                </tr>
                <tr>
                  <th>ゲートバー</th>
                  <td>グラスファイバー（最長：4300ｍｍ）</td>
                </tr>
                <tr>
                  <th>開閉速度</th>
                  <td>1.2〜5秒で可変可能</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <ServiceFooter />
      </div>
    </Layout>
  )
}

export default Gate
